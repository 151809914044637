import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { ROLE, TOKEN } from '../../../_helpers/token';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };
  componentDidMount() {
    // axios.get(baseURL).then(res => { })

    if (TOKEN == null) {
      window.location.href = "/log_in";

    }
  }
  logout = () => {
    localStorage.removeItem('token');
  }
  render() {
    const { changeToLight, changeToDark } = this.props;
    // alert(ROLE)
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarCategory title="Layout" icon="layers">
      <button className="sidebar__link" type="button" onClick={changeToLight}>
      <p className="sidebar__link-title">Light Theme</p>
      </button>
      <button className="sidebar__link" type="button" onClick={changeToDark}>
      <p className="sidebar__link-title">Dark Theme</p>
    </button> */}
          {/* </SidebarCategory> */}

        </ul>
        {/* 
  // finance */}


        {ROLE === 'admin' ?
          <>
            <SidebarLink
              title="User Search"
              icon="user"
              route="/user"
              onClick={this.hideSidebar}
            />
            <SidebarCategory title="Today Management" icon="book">

              <SidebarLink title="Gaming Income" route="/gamingincome" onClick={this.hideSidebar} />
              <SidebarLink title="NetCashFlow" route="/netcashflow" onClick={this.hideSidebar} />
              <SidebarLink title="Times played" route="/timesplayed" onClick={this.hideSidebar} />

              <SidebarLink title="JackPot Winners" route="/jackpot" onClick={this.hideSidebar} />
              <SidebarLink title="JackPot Counts" route="/jackpotcount" onClick={this.hideSidebar} />
              <SidebarLink title="Wins vs Losses" route="/winloss" onClick={this.hideSidebar} />
              <SidebarLink title="JackPot Counts" route="/jackpotmonthly" onClick={this.hideSidebar} />
              <SidebarCategory title="Bets">
                <SidebarLink title="Bets Per Day" route="/betsperday" onClick={this.hideSidebar} />
                <SidebarLink title="Bets Per Hour" route="/betsperhour" onClick={this.hideSidebar} />
                <SidebarLink title="Bets Per Type" route="/betstype" onClick={this.hideSidebar} />
                {/* <SidebarLink title="Other Winners" route="/otherwinners" onClick={this.hideSidebar} /> */}
                <SidebarLink title="Average Bets" route="/averagebets" onClick={this.hideSidebar} />
              </SidebarCategory>

              <SidebarCategory title="Deposits">
                <SidebarLink title="Deposit Requests" route="/DepositRequests" onClick={this.hideSidebar} />
                <SidebarLink title="Daily Deposits" route="/dailydeposit" onClick={this.hideSidebar} />
                <SidebarLink title="Deposit Per Hour" route="/deposit_per_hour" onClick={this.hideSidebar} />

              </SidebarCategory>


              <SidebarCategory title="Withdrawals">
                <SidebarLink title="Withdrawals Summary" route="/withdrawalsummary" onClick={this.hideSidebar} />
                <SidebarLink title="Withdrawals" route="/transaction" onClick={this.hideSidebar} />
              </SidebarCategory>


            </SidebarCategory>

            <SidebarCategory title="This Month Business" icon="book">
              <SidebarLink title="Gaming Income" route="/incomemonthly" onClick={this.hideSidebar} />

              <SidebarCategory title="Deposits">
                <SidebarLink title="Deposits Summary" route="/depositsmonthly" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarCategory title="Withdrawals">
                <SidebarLink title="Withdrawals Summary" route="/withdrawlsummarymonthly" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarLink title="Bets" route="/betmonthly" onClick={this.hideSidebar} />
            </SidebarCategory>
            <SidebarLink
              title="Social Media Posts"
              icon="file-add"
              route="/socialmediaposts"
              onClick={this.hideSidebar}
            />

            <SidebarLink
              title="Full Winners' Info"
              icon="user"
              route="/customerfullinfo"
              onClick={this.hideSidebar}
            />

            <SidebarCategory title="MPESA Reconciliations" icon="briefcase">

              <SidebarLink title="Luckybox" route="/luckybox" onClick={this.hideSidebar} />

              <SidebarLink title="Lucky4" route="/Lucky4" onClick={this.hideSidebar} />

              <SidebarLink title="PesaCode" route="/PesaCode" onClick={this.hideSidebar} />

            </SidebarCategory>

            <SidebarCategory title="Customers" icon="book">
              <SidebarLink title="Newly Recruited" route="/newlycustomer" onClick={this.hideSidebar} />

              {/* <SidebarLink title="Incoming calls" route="/customerfeedback" onClick={this.hideSidebar} />
                
                <SidebarLink title="Out going calls" route="/outgoingcustomerfeedbacks" onClick={this.hideSidebar} />
                
              <SidebarLink title="Calls Analysis" route="/customersaftercall" onClick={this.hideSidebar} /> */}

              <SidebarLink
                title="Incoming Messages"
                route="/customerincomingMessages"
                onClick={this.hideSidebar}
              />
              <SidebarLink title="Active Customers" route="/activecustomers" onClick={this.hideSidebar} />
              {/* <SidebarLink title="Total Unsuccessful Depositors" route="/depositattemptscustomer" onClick={this.hideSidebar} /> */}
              <SidebarLink title="InActive Customers (Less than a month)" route="/inactivecustomers" onClick={this.hideSidebar} />
              <SidebarLink title="InActive Customers (Over a month)" route="/inactivecustomersmonth" onClick={this.hideSidebar} />
              <SidebarLink title="Customer Recruitment" route="/customersubscription" onClick={this.hideSidebar} />

              <SidebarCategory title="Times Played">

                <SidebarLink title="For Today" route="/threedayscustomers" onClick={this.hideSidebar} />

                <SidebarLink title="Less than thrice for Yesterday" route="/yesterdaycustomers" onClick={this.hideSidebar} />

                <SidebarLink title="Less than 4 times in a month" route="/playforthtimescustomers" onClick={this.hideSidebar} />

                <SidebarLink title="Less than four times over a month" route="/playoveramonth" onClick={this.hideSidebar} />
              </SidebarCategory>
              {/* 

                  <SidebarLink title="Other Winners" route="/otherwinners" onClick={this.hideSidebar} />
                  <SidebarLink title="Average Bets" route="/averagebets" onClick={this.hideSidebar} /> */}
            </SidebarCategory>


            <SidebarCategory title="Bets" icon="book">
              <SidebarLink title="Cohort Analysis" route="/cohortAnalysis" onClick={this.hideSidebar} />

              <SidebarLink title="Bets Per Day" route="/betsperday" onClick={this.hideSidebar} />
              <SidebarLink title="Bets Per Hour" route="/betsperhour" onClick={this.hideSidebar} />

              <SidebarLink title="Bets Per Type" route="/betstype" onClick={this.hideSidebar} />
              <SidebarLink title="JackPot" route="/jackpot" onClick={this.hideSidebar} />
              <SidebarLink title="JackPot Per Day" route="/jackpotcount" onClick={this.hideSidebar} />

              <SidebarLink title="Other Winners" route="/otherwinners" onClick={this.hideSidebar} />
              <SidebarLink title="Average Bets" route="/averagebets" onClick={this.hideSidebar} />
            </SidebarCategory>

            <ul className="sidebar__block">




              <SidebarCategory title="Finance" icon="briefcase">

                <SidebarLink title="Airtime Disbursement" route="/airtime" onClick={this.hideSidebar} />

                <SidebarLink title="Gaming Income" route="/gamingincome" onClick={this.hideSidebar} />

                <SidebarLink title="House Income Report" route="/houseincome" onClick={this.hideSidebar} />
                <SidebarLink title="EWallet" route="/ewallet" onClick={this.hideSidebar} />
                <SidebarLink title="Stake" route="/stake" onClick={this.hideSidebar} />
                <SidebarLink title="NetCashFlow" route="/netcashflow" onClick={this.hideSidebar} />
                <SidebarLink title="Wins vs Losses" route="/winloss" onClick={this.hideSidebar} />
                <SidebarLink title="JackPot Counts" route="/jackpotmonthly" onClick={this.hideSidebar} />

                <SidebarCategory title="Deposits">

                  <SidebarLink title="Deposit Requests" route="/DepositRequests" onClick={this.hideSidebar} />


                  <SidebarLink title="Daily Deposits" route="/dailydeposit" onClick={this.hideSidebar} />
                  <SidebarLink title="Deposit Per Hour" route="/deposit_per_hour" onClick={this.hideSidebar} />

                </SidebarCategory>


                <SidebarCategory title="Withdrawals">
                  <SidebarLink title="Withdrawals Summary" route="/withdrawalsummary" onClick={this.hideSidebar} />
                  <SidebarLink title="Withdrawals" route="/transaction" onClick={this.hideSidebar} />
                </SidebarCategory>
              </SidebarCategory>
            </ul>

            <ul className="sidebar__block">

              <SidebarCategory title="Report" icon="book">
                <SidebarLink title="MO Report" route="/mo_counts" onClick={this.hideSidebar} />

                {/* <SidebarLink title="Revenue Channel" route="/revenuechannel" onClick={this.hideSidebar} /> */}
                <SidebarLink title="Times played" route="/timesplayed" onClick={this.hideSidebar} />
                <SidebarLink title="Summary" route="/summary" onClick={this.hideSidebar} />
                <SidebarLink title="EOD" route="/eod" onClick={this.hideSidebar} />

              </SidebarCategory>
              {/* <SidebarCategory title="Blasts" icon="phone">
                              <SidebarLink title="Send Blast Last Active" route="/sendmonthly" onClick={this.hideSidebar} />
                              <SidebarLink title="Active Depositors" route="/active_blasts" onClick={this.hideSidebar} />

                              <SidebarLink title="Played counts" route="/blastcountplayed" onClick={this.hideSidebar} />

                              <SidebarLink title="Schedule blast message" route="/blasts" onClick={this.hideSidebar} />
                              <SidebarLink title="Scheduled Messages" route="/scheduled_message" onClick={this.hideSidebar} />


                              <SidebarLink title="Queue Messages" route="/bulksmsmessage" onClick={this.hideSidebar} />
                              </SidebarCategory> */}
            </ul>


            <ul className="sidebar__block">
              <SidebarCategory title="Audit Trails" icon="briefcase">
                <SidebarLink title="Login Audits" route="/audit" onClick={this.hideSidebar} />
                <SidebarLink title="Customer Perfomance" route="/audit_performance" onClick={this.hideSidebar} />
              </SidebarCategory>
            </ul>

            <ul className="sidebar__block">
              <SidebarLink title="Blacklist" route="/addblacklist" icon="users" onClick={this.hideSidebar} />
            </ul>



          </>
          :


          ROLE == 'presenter' ?
          <>
            <SidebarLink
              title="Deposits"
              icon="briefcase"
              route="/transaction_deposit"
              onClick={this.hideSidebar}
            />
            <SidebarLink
            title="Winnings"
            icon="briefcase"
            route="/transaction_withdrawal"
            onClick={this.hideSidebar}
          />
          </>
            :
            ROLE == 'ccr' ?

              <>
                <SidebarLink
                  title="User Search"
                  icon="user"
                  route="/user"
                  onClick={this.hideSidebar}
                />
              </>
              :
              <div className="sidebar__content">
                <div>

                  <ul className="sidebar__block">
                    <SidebarLink
                      title="Customers"
                      icon="users"
                      route="/customer_prese"
                      onClick={this.hideSidebar}
                    />

                    <SidebarLink
                      title="Customers Per Day"
                      icon="users"
                      route="/per_day_customers"
                      onClick={this.hideSidebar}
                    />
                    <SidebarLink
                      title="Deposits"
                      icon="briefcase"
                      route="/transaction_deposit"
                      onClick={this.hideSidebar}
                    />

                    <SidebarLink
                      title="Winnings"
                      icon="briefcase"
                      route="/transaction_withdrawal"
                      onClick={this.hideSidebar}
                    />

                    <SidebarLink
                      title="Transaction"
                      icon="briefcase"
                      route="/transaction"
                      onClick={this.hideSidebar}
                    />

                  </ul>
                </div>
              </div>

        }



        <ul className="sidebar__block">
          <SidebarLink
            title="LogOut"
            route="/log_in"
            onClick={this.logout}
            icon="user"
          />
        </ul>

      </div >
    );
  }
}

export default SidebarContent;
